import { postSaveApi } from "./api-base";
import {
  testAnswerType,
  apiTestWorkItem,
  apiLoadTestItem,
  announceType,
  announceTargetType,
  additionalInfoExperienceYearType,
  apiRegisterUser,
  apiUpdateUser,
  apiRegisterUserInvite,
  apiUserInviteResponse,
  apiAccountInviteResponse,
  apiRegisterContentsWork,
  apiReleaseContents,
  apiUpdateContentsWork,
  apiUpdateContents,
  apiRegisterPersonnelChange,
  apiRegisterInquiry,
  apiRegisterInquiryResponse,
  apiRegisterInquiryWorkComment,
  apiUpdateInquiry,
  apiUpdateInquiryResponse,
  apiRegisterPackage,
  apiUpdatePackage,
  apiRegisterView,
  apiUpdateRestartView,
  apiUpdateCompleteView,
  apiRegisterTest,
  careerType,
  additionalInfoOccupationType,
  searchDemoType,
  searchPackageType,
  searchAccountPlanType,
  searchPackageFilterType,
  packageAccountBulkType,
  apiUpdateAccountNotice,
  apiAccount,
  apiRegisterAccountRequest,
  apiSendReport,
  apiBulkRegisterUser,
  apiBulkRegisterUserOne,
} from "../../types";
import { getAccountOriginalParams } from "./../../util/common.util";

const saveApiBase = (eventType: string, props?: object) => {
  return postSaveApi({ eventType, ...props });
};

export const registerBookmarkApi = (props: { accountOriginalFlg: boolean; contentsId: number; packageId: number }) => {
  return saveApiBase("REGISTER_BOOKMARK", props);
};

export const registerReportApi = (props: { accountOriginalFlg: boolean; themeId: number; report: string }) => {
  return saveApiBase("REGISTER_REPORT", props);
};
export const registerTestApi = (params: apiRegisterTest) => {
  return saveApiBase("REGISTER_TEST", params);
};

export const registerViewApi = (params: apiRegisterView) => {
  return saveApiBase("REGISTER_VIEW", params);
};

export const registerInquiryApi = (params: apiRegisterInquiry) => {
  return saveApiBase("REGISTER_INQUIRY", params);
};

export const registerInquiryResponseDmApi = (params: apiRegisterInquiryResponse) => {
  return saveApiBase("REGISTER_INQUIRY_RESPONSE_DM", params);
};

export const registerInquiryTempResponseDmApi = (params: apiRegisterInquiryResponse) => {
  return saveApiBase("REGISTER_INQUIRY_TEMP_RESPONSE_DM", params);
};

export const registerInquiryWorkCommentApi = (params: apiRegisterInquiryWorkComment) => {
  return saveApiBase("REGISTER_INQUIRY_WORK_COMMENT", params);
};

export const registerInquiryResponseUserApi = (params: apiRegisterInquiryResponse) => {
  return saveApiBase("REGISTER_INQUIRY_RESPONSE_USER", params);
};

export const registerFrontError = (props: { menuUrl: string; errorMessage: string }) => {
  return saveApiBase("REGISTER_FRONT_ERROR", props);
};

export const registerAccountApi = (params: apiRegisterAccountRequest) => {
  return saveApiBase("REGISTER_ACCOUNT", params);
};

export const registerCorporationAccountApi = (props: {
  id: string;
  name: string;
  dmAdminUrl: string;
  usageStartDate: Date | string;
  demoFlg: boolean;
  accountIds: string[];
}) => {
  return saveApiBase("REGISTER_CORPORATION_ACCOUNT", props);
};

export const registerUserApi = (props: { items: apiRegisterUser }) => {
  const items = props.items;
  const withAccountId = items.accountId !== undefined ? { accountId: items.accountId } : {};
  return saveApiBase("REGISTER_USER", {
    ...withAccountId,
    ...items.basicInformation,
    ...(items.additionalInformation ? { userInfo: items.additionalInformation } : {}),
  });
};

export const registerCategoryGroupApi = (props: { name: string; ord: number; type: string; status: string }) => {
  return saveApiBase("REGISTER_CATEGORY_GROUP", props);
};

export const registerCategoryApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  categoryGroupId: number;
  name: string;
  ord: number;
  description: string;
  image: string;
}) => {
  return saveApiBase("REGISTER_CATEGORY", {
    categoryGroupId: props.categoryGroupId,
    name: props.name,
    ord: props.ord,
    description: props.description,
    image: props.image,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const registerThemeApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  categoryId: number;
  categoryGenreId: number;
  name: string;
  ord: number;
  impressionFlg: boolean;
  description: string;
  image: string;
  documentPath: string;
}) => {
  return saveApiBase("REGISTER_THEME", {
    categoryId: props.categoryId,
    categoryGenreId: props.categoryGenreId,
    name: props.name,
    ord: props.ord,
    impressionFlg: props.impressionFlg,
    description: props.description,
    image: props.image,
    documentPath: props.documentPath,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const registerContentsWorkApi = (params: apiRegisterContentsWork) => {
  return saveApiBase("REGISTER_CONTENTS_WORK", {
    ...params,
    ...getAccountOriginalParams(params.accountOriginalFlg, params.accountId),
  });
};

export const registerContentsAccountApi = (props: {
  categoryId: number;
  themeId: number;
  name: string;
  ord: number;
  playtime: number;
  requiredTest: boolean;
  description: string;
  image: string;
  contentsUrl: string;
}) => {
  return saveApiBase("REGISTER_CONTENTS_ACCOUNT", props);
};

export const registerTestTemplateWorkApi = (testTemplates: apiTestWorkItem) => {
  return saveApiBase("REGISTER_TEST_TEMPLATE_WORK", {
    // 階層構造が深いのでLoadで使っているapiLoadTestItemの型で定義
    contentsWorkId: testTemplates.contentsWorkId,
    questionType: testTemplates.questionType,
    question: testTemplates.question,
    questionImage: testTemplates.questionImage,
    answerTemplateType: testTemplates.answerTemplateType,
    correctAnswerType: testTemplates.correctAnswerType,
    correctAnswerDescription: testTemplates.correctAnswerDescription,
    incorrectAnswerDescription: testTemplates.incorrectAnswerDescription,
    questionNo: testTemplates.questionNo,
    correctAnswerCount: testTemplates.correctAnswerCount,
    templateDtls: testTemplates.templateDtls,
  });
};

export const registerContentsMediaApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  name: string;
  contentsMediaConvertId: number;
  playtime: number;
  thumbnailType: string;
  thumbnailPath: string;
}) => {
  return saveApiBase("REGISTER_CONTENTS_MEDIA", {
    ...props,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const registerTestTemplateCategoryApi = (props: { level: number }) => {
  return saveApiBase("REGISTER_TEST_TEMPLATE_CATEGORY", props);
};

export const registerTestCategoryApi = (props: { testTemplateCategoryId: number; results: testAnswerType[] }) => {
  return saveApiBase("REGISTER_TEST_CATEGORY", props);
};

export const registerStudyGroupApi = (props: { name: string; accountId?: string }) => {
  return props.accountId
    ? saveApiBase("REGISTER_STUDY_GROUP", { name: props.name, accountId: props.accountId })
    : saveApiBase("REGISTER_STUDY_GROUP", { name: props.name });
};

export const registerLegalCurriculumApi = (props: {
  startYear: number;
  legalCurriculumTemplateId: number;
  studyGroupIds: number[];
  dtls: {
    month: number;
    themeId: number;
    monthOrd: number;
    themeOrd: number;
  }[];
  accountId?: string;
}) => {
  return props.accountId
    ? saveApiBase("REGISTER_LEGAL_CURRICULUM", props)
    : saveApiBase("REGISTER_LEGAL_CURRICULUM", {
        startYear: props.startYear,
        legalCurriculumTemplateId: props.legalCurriculumTemplateId,
        studyGroupIds: props.studyGroupIds,
        dtls: props.dtls,
      });
};

export const registerLegalCurriculumStudiedForceApi = (props: {
  legalCurriculumId: number;
  userId: number;
  month: number;
}) => {
  return saveApiBase("REGISTER_LEGAL_CURRICULUM_STUDIED_FORCE", props);
};

export const registerCareerExclusionApi = (props: {
  studyGroupId: number;
  careerType: careerType;
  level: number;
  themeIds: number[];
  accountId?: string;
}) => {
  return props.accountId
    ? saveApiBase("REGISTER_CAREER_EXCLUSION", props)
    : saveApiBase("REGISTER_CAREER_EXCLUSION", {
        studyGroupId: props.studyGroupId,
        careerType: props.careerType,
        level: props.level,
        themeIds: props.themeIds,
      });
};

export const registerAnnounceApi = (props: {
  announceType: announceType;
  htmlFlg: boolean | number;
  importantFlg: boolean | number;
  title: string;
  contents: string;
  target: announceTargetType;
  publicPageFlg: boolean | number;
  startDate: Date | string | null;
  bannerEndDate: Date | string | null;
  fileObjectKey: string;
  fileName: string | undefined;
  linkLabel: string | undefined;
}) => {
  return saveApiBase("REGISTER_ANNOUNCE", props);
};

export const registerAnnounceReadedApi = (props: { ids: { id: number; targetType: announceTargetType }[] }) => {
  return saveApiBase("REGISTER_ANNOUNCE_READED", props);
};

export const registerAnnounceClosedApi = (props: { id: number }) => {
  return saveApiBase("REGISTER_ANNOUNCE_CLOSED", props);
};

export const registerCategoryGenreApi = (props: { name: string; ord: number }) => {
  return saveApiBase("REGISTER_CATEGORY_GENRE", props);
};

export const registerContentsEvaluationApi = (props: { contentsId: number; helpful: boolean }) => {
  return saveApiBase("REGISTER_CONTENTS_EVALUATION", props);
};

export const registerMailAddressApi = (props: { mailAddress: string }) => {
  return saveApiBase("REGISTER_MAIL_ADDRESS", props);
};

export const registerUserAdditionalInfoApi = (props: {
  lastName: string;
  firstName: string;
  occupation: additionalInfoOccupationType;
  experienceYear: additionalInfoExperienceYearType;
  joinedCompanyDate?: Date | string | null;
  birthDate?: Date | string | null;
}) => {
  return saveApiBase("REGISTER_USER_INFO", props);
};

export const registerCareerApi = (props: {
  careerType: careerType;
  level: number;
  name: string;
  description: string;
  image: string;
}) => {
  return saveApiBase("REGISTER_CAREER", props);
};

export const registerCareerThemeWorkApi = (props: {
  careerType: "KANGO" | "KAIGO";
  level: number;
  dtls: { ord: number; themeId: number }[];
}) => {
  return saveApiBase("REGISTER_CAREER_THEME_WORK", props);
};

export const registerUserInvite = (props: { userInvite: apiRegisterUserInvite }): Promise<apiUserInviteResponse> => {
  return saveApiBase("REGISTER_USER_INVITE", { ...props.userInvite });
};

export const registerAccountInvite = (props: { accountId: string }): Promise<apiAccountInviteResponse> => {
  return saveApiBase("REGISTER_ACCOUNT_INVITE", props);
};

export const registerReInvite = (props: {
  inviteUlid: string;
}): Promise<apiUserInviteResponse | apiAccountInviteResponse> => {
  return saveApiBase("REGISTER_RE_INVITE", props);
};

export const registerChangeLoginStartApi = (props: {
  loginInfo:
    | {
        loginType: "PASSWORD";
        password: string;
      }
    | { loginType: "EMAIL"; mailAddress: string }
    | { loginType: "SMS"; phoneNumber: string };
}): Promise<void> => {
  return saveApiBase("REGISTER_CHANGE_LOGIN_START", { ...props.loginInfo });
};

export const registerPersonnelChangeApi = (params: apiRegisterPersonnelChange) => {
  return saveApiBase("REGISTER_PERSONNEL_CHANGE", params);
};

export const registerInquiryReadedApi = (props: { id: number }) => {
  return saveApiBase("REGISTER_INQUIRY_READED", props);
};

export const registerPackageApi = (params: apiRegisterPackage) => {
  return saveApiBase("REGISTER_PACKAGE", params);
};

export const registerPackageAccountApi = (params: { accountId: string; packageIds: number[] }) => {
  return saveApiBase("REGISTER_PACKAGE_ACCOUNT", params);
};

export const registerBulkPackageAccountApi = (params: {
  packageIds: number[] | undefined;
  linkType: packageAccountBulkType | undefined;
  name: string | undefined;
  validOnly: boolean | undefined;
  id: string | undefined;
  demo: searchDemoType | undefined;
  package: searchPackageType | undefined;
  plan: searchAccountPlanType | undefined;
  validUsageEndDateOnly: boolean | undefined;
  corporationId: string | undefined;
  packageIdsFilter: number[] | undefined;
  packageFilterType: searchPackageFilterType | undefined;
}) => {
  return saveApiBase("REGISTER_BULK_PACKAGE_ACCOUNT", params);
};

export const copyAnnounceApi = (props: { id: number }) => {
  return saveApiBase("COPY_ANNOUNCE", props);
};

export const generateProfileUploadUrlApi = (props: { fileName: string }) => {
  return saveApiBase("GENERATE_PROFILE_UPLOAD_URL", props);
};

export const generateMediaUploadUrlApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  fileName: string;
}) => {
  return saveApiBase("GENERATE_MEDIA_UPLOAD_URL", {
    fileName: props.fileName,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const generateMediaDownloadUrlApi = (props: { contentsMediaConvertId: number }) => {
  return saveApiBase("GENERATE_MEDIA_DOWNLOAD_URL", props);
};

export const generateThumbnailUploadUrlApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  fileName: string;
}) => {
  return saveApiBase("GENERATE_THUMBNAIL_UPLOAD_URL", {
    fileName: props.fileName,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const generateAnnounceFileDownloadUrlApi = (props: { id: number }) => {
  return saveApiBase("GENERATE_ANNOUNCE_FILE_DOWNLOAD_URL", props);
};

export const generateAnnounceFileUploadUrlApi = (props: { fileName: string }) => {
  return saveApiBase("GENERATE_ANNOUNCE_FILE_UPLOAD_URL", props);
};

export const generateImportUserUploadUrlApi = (props: { fileName: string; accountId?: string }) => {
  return props.accountId
    ? saveApiBase("GENERATE_IMPORT_USER_UPLOAD_URL", props)
    : saveApiBase("GENERATE_IMPORT_USER_UPLOAD_URL", { fileName: props.fileName });
};

export const generateBulkUserDownloadUrlApi = (props: { userBulkRegisterId: number }) => {
  return saveApiBase("GENERATE_BULK_USER_DOWNLOAD_URL", props);
};

export const generateSubtitlesUploadUrlApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  fileName: string;
}) => {
  return saveApiBase("GENERATE_SUBTITLES_UPLOAD_URL", {
    fileName: props.fileName,
    accountId: props.accountId,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const generateSubtitlesDownloadUrlApi = (props: {
  accountOriginalFlg: boolean;
  work: boolean;
  id: number;
  language: string;
}) => {
  return saveApiBase("GENERATE_SUBTITLES_DOWNLOAD_URL", props);
};

export const generateDocumentUploadUrlApi = (props: { fileName: string }) => {
  return saveApiBase("GENERATE_DOCUMENT_UPLOAD_URL", props);
};

export const generateDocumentDownloadUrlApi = (props: { themeId: number }) => {
  return saveApiBase("GENERATE_DOCUMENT_DOWNLOAD_URL", props);
};

export const generateLegalCurriculumDocumentApi = (props: { legalCurriculumId: number }) => {
  return saveApiBase("GENERATE_LEGAL_CURRICULUM_DOCUMENT", props);
};

export const generateLegalCurriculumDocumentDownloadUrlApi = (props: { legalCurriculumId: number }) => {
  return saveApiBase("GENERATE_LEGAL_CURRICULUM_DOCUMENT_DOWNLOAD_URL", props);
};

export const generateInquiryUploadUrlApi = (props: { fileName: string }) => {
  return saveApiBase("GENERATE_INQUIRY_UPLOAD_URL", props);
};

export const generateInquiryDownloadUrlApi = (props: { id: number; work: boolean }) => {
  return saveApiBase("GENERATE_INQUIRY_DOWNLOAD_URL", props);
};

export const generateUserDownloadUrlApi = (props: { accountId?: string }) => {
  return props.accountId
    ? saveApiBase("GENERATE_USER_DOWNLOAD_URL", {
        accountId: props.accountId,
      })
    : saveApiBase("GENERATE_USER_DOWNLOAD_URL", {});
};

export const importUserApi = (props: { importFileId: number }) => {
  return saveApiBase("IMPORT_USER", props);
};

export const importUserPreviewApi = (props: { importFileId: number }) => {
  return saveApiBase("IMPORT_USER_PREVIEW", props);
};

export const bulkRegisterUserApi = (props: apiBulkRegisterUser) => {
  return props.accountId
    ? saveApiBase("BULK_REGISTER_USER", {
        userBulkRegisterId: props.userBulkRegisterId,
        users: props.users,
        accountId: props.accountId,
      })
    : saveApiBase("BULK_REGISTER_USER", {
        userBulkRegisterId: props.userBulkRegisterId,
        users: props.users,
      });
};

export const bulkRegisterUserOneApi = (props: apiBulkRegisterUserOne) => {
  return props.accountId
    ? saveApiBase("BULK_REGISTER_USER_ONE", {
        user: props.user,
        accountId: props.accountId,
      })
    : saveApiBase("BULK_REGISTER_USER_ONE", {
        user: props.user,
      });
};

export const bulkRegisterUserWorkApi = (props: apiBulkRegisterUser) => {
  return props.accountId
    ? saveApiBase("BULK_REGISTER_USER_WORK", {
        userBulkRegisterId: props.userBulkRegisterId,
        users: props.users,
        accountId: props.accountId,
      })
    : saveApiBase("BULK_REGISTER_USER_WORK", {
        userBulkRegisterId: props.userBulkRegisterId,
        users: props.users,
      });
};

export const releaseContentsApi = (params: apiReleaseContents) => {
  return saveApiBase("RELEASE_CONTENTS", {
    ...params,
    ...getAccountOriginalParams(params.accountOriginalFlg, params.accountId),
  });
};

export const releaseContentsBulkApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  categoryId: number;
  themeId: number;
}) => {
  return saveApiBase("RELEASE_CONTENTS_BULK", {
    categoryId: props.categoryId,
    themeId: props.themeId,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const releaseAnnounceApi = (props: { id: number }) => {
  return saveApiBase("RELEASE_ANNOUNCE", props);
};

export const releaseCareerThemeApi = (props: { careerType: careerType; level: number }) => {
  return saveApiBase("RELEASE_CAREER_THEME", props);
};

export const releasePackageApi = (props: { id: number }) => {
  return saveApiBase("RELEASE_PACKAGE", props);
};

export const closeContentsApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  categoryId: number;
  themeId: number;
  contentsId: number;
}) => {
  return saveApiBase("CLOSE_CONTENTS", {
    categoryId: props.categoryId,
    themeId: props.themeId,
    contentsId: props.contentsId,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const closeContentsBulkApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  categoryId: number;
  themeId: number;
}) => {
  return saveApiBase("CLOSE_CONTENTS_BULK", {
    categoryId: props.categoryId,
    themeId: props.themeId,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const closeAnnounceApi = (props: { id: number }) => {
  return saveApiBase("CLOSE_ANNOUNCE", props);
};

export const closePackageApi = (props: { id: number }) => {
  return saveApiBase("CLOSE_PACKAGE", props);
};

export const rereleaseContentsApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("RERELEASE_CONTENTS", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const rereleasePackageApi = (props: { id: number }) => {
  return saveApiBase("RERELEASE_PACKAGE", props);
};

export const restartCategoryApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("RESTART_CATEGORY", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const restartThemeApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("RESTART_THEME", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const restartContentsApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("RESTART_CONTENTS", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const updateReportApi = (props: { accountOriginalFlg: boolean; reportId: number; report: string }) => {
  return saveApiBase("UPDATE_REPORT", props);
};

export const updateInquiryApi = (params: apiUpdateInquiry) => {
  return saveApiBase("UPDATE_INQUIRY", params);
};

export const updateInquirySolvedApi = (props: { inquiryId: number }) => {
  return saveApiBase("UPDATE_INQUIRY_SOLVED", props);
};

export const updateInquirySolvedDmApi = (props: { inquiryId: number }) => {
  return saveApiBase("UPDATE_INQUIRY_SOLVED_DM", props);
};

export const updateInquiryUnsolvedApi = (props: { inquiryId: number }) => {
  return saveApiBase("UPDATE_INQUIRY_UNSOLVED", props);
};

export const updateInquiryUnsolvedDmApi = (props: { inquiryId: number }) => {
  return saveApiBase("UPDATE_INQUIRY_UNSOLVED_DM", props);
};

export const updateRestartViewApi = (props: apiUpdateRestartView) => {
  return saveApiBase("RESTART_VIEW", props);
};

export const updateStopViewApi = (props: {
  accountOriginalFlg: boolean;
  viewingHistoryContentsId: number;
  replayTime: number;
}) => {
  return saveApiBase("STOP_VIEW", props);
};

export const updateUserApi = (items: apiUpdateUser) => {
  return saveApiBase("UPDATE_USER", {
    ...items.basicInformation,
    ...(items.additionalInformation ? { userInfo: items.additionalInformation } : {}),
  });
};

export const updateRestartUserApi = (props: { id: number }) => {
  return saveApiBase("RESTART_USER", props);
};

export const updateRestartAccountApi = (props: { id: string }) => {
  return saveApiBase("RESTART_ACCOUNT", props);
};

export const updateRestartCorporationAccountApi = (props: { id: string }) => {
  return saveApiBase("RESTART_CORPORATION_ACCOUNT", props);
};

export const updateCompleteViewApi = (props: apiUpdateCompleteView) => {
  return saveApiBase("COMPLETE_VIEW", props);
};

export const updateReportStatusConfirmdApi = (props: { accountOriginalFlg: boolean; reportId: number }) => {
  return saveApiBase("UPDATE_REPORT_STATUS_CONFIRM", props);
};

export const updateReportStatusUnconfirmApi = (props: { accountOriginalFlg: boolean; reportId: number }) => {
  return saveApiBase("UPDATE_REPORT_STATUS_UNCONFIRM", props);
};

export const updateInquiryResponseDmApi = (params: apiUpdateInquiryResponse) => {
  return saveApiBase("UPDATE_INQUIRY_RESPONSE_DM", params);
};

export const updateInquiryResponseUserApi = (params: apiUpdateInquiryResponse) => {
  return saveApiBase("UPDATE_INQUIRY_RESPONSE_USER", params);
};

export const updateAccountApi = (params: apiAccount) => {
  return saveApiBase("UPDATE_ACCOUNT", params);
};

export const updateCorporationAccountApi = (props: {
  id: string;
  name: string;
  dmAdminUrl: string;
  usageStartDate: string;
  usageEndDate: string | null;
  demoFlg: boolean;
  accountIds: string[];
}) => {
  return saveApiBase("UPDATE_CORPORATION_ACCOUNT", props);
};

export const updateCategoryApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  id: number;
  categoryGroupId: number;
  name: string;
  ord: number;
  description: string;
  image: string;
}) => {
  return saveApiBase("UPDATE_CATEGORY", {
    id: props.id,
    categoryGroupId: props.categoryGroupId,
    name: props.name,
    ord: props.ord,
    description: props.description,
    image: props.image,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const updateCategoryFixedApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  id: number;
  name: string;
  ord: number;
  description: string;
  image: string;
}) => {
  return saveApiBase("UPDATE_CATEGORY_FIXED", {
    id: props.id,
    name: props.name,
    ord: props.ord,
    description: props.description,
    image: props.image,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const updateThemeApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  id: number;
  categoryId: number;
  categoryGenreId: number;
  name: string;
  ord: number;
  impressionFlg: boolean;
  description: string;
  image: string;
  documentPath: string;
}) => {
  return saveApiBase("UPDATE_THEME", {
    id: props.id,
    categoryId: props.categoryId,
    categoryGenreId: props.categoryGenreId,
    name: props.name,
    ord: props.ord,
    impressionFlg: props.impressionFlg,
    description: props.description,
    image: props.image,
    documentPath: props.documentPath,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const updateThemeFixedApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  id: number;
  categoryGenreId: number;
  name: string;
  ord: number;
  description: string;
  image: string;
  documentPath: string;
}) => {
  return saveApiBase("UPDATE_THEME_FIXED", {
    id: props.id,
    categoryGenreId: props.categoryGenreId,
    name: props.name,
    ord: props.ord,
    description: props.description,
    image: props.image,
    documentPath: props.documentPath,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const updateContentsApi = (params: apiUpdateContents) => {
  return saveApiBase("UPDATE_CONTENTS", {
    ...params,
    ...getAccountOriginalParams(params.accountOriginalFlg, params.accountId),
  });
};

export const updateContentsWorkApi = (params: apiUpdateContentsWork) => {
  return saveApiBase("UPDATE_CONTENTS_WORK", {
    ...params,
    ...getAccountOriginalParams(params.accountOriginalFlg, params.accountId),
  });
};

export const updateTestTemplateApi = (testTemplates: apiLoadTestItem) => {
  return saveApiBase("UPDATE_TEST_TEMPLATE", {
    // 階層構造が深いのでLoadで使っているapiLoadTestItemの型で定義
    id: testTemplates.id,
    contentsId: testTemplates.contentsId,
    questionType: testTemplates.questionType,
    question: testTemplates.question,
    questionImage: testTemplates.questionImage,
    answerTemplateType: testTemplates.answerTemplateType,
    correctAnswerType: testTemplates.correctAnswerType,
    correctAnswerDescription: testTemplates.correctAnswerDescription,
    incorrectAnswerDescription: testTemplates.incorrectAnswerDescription,
    questionNo: testTemplates.questionNo,
    correctAnswerCount: testTemplates.correctAnswerCount,
    templateDtls: testTemplates.templateDtls,
  });
};

export const updateTestTemplateWorkApi = (testTemplates: apiTestWorkItem) => {
  return saveApiBase("UPDATE_TEST_TEMPLATE_WORK", {
    // 階層構造が深いのでLoadで使っているapiLoadTestItemの型で定義
    id: testTemplates.id,
    contentsWorkId: testTemplates.contentsWorkId,
    questionType: testTemplates.questionType,
    question: testTemplates.question,
    questionImage: testTemplates.questionImage,
    answerTemplateType: testTemplates.answerTemplateType,
    correctAnswerType: testTemplates.correctAnswerType,
    correctAnswerDescription: testTemplates.correctAnswerDescription,
    incorrectAnswerDescription: testTemplates.incorrectAnswerDescription,
    questionNo: testTemplates.questionNo,
    correctAnswerCount: testTemplates.correctAnswerCount,
    templateDtls: testTemplates.templateDtls,
  });
};

export const updateContentsMediaApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  id: number;
  name: string;
  contentsMediaConvertId: number;
  playtime: number;
  thumbnailType: string;
  thumbnailPath: string;
}) => {
  return saveApiBase("UPDATE_CONTENTS_MEDIA", {
    id: props.id,
    name: props.name,
    contentsMediaConvertId: props.contentsMediaConvertId,
    playtime: props.playtime,
    thumbnailType: props.thumbnailType,
    thumbnailPath: props.thumbnailPath,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const updateStudyGroupApi = (props: { id: number; name: string; accountId?: string }) => {
  return props.accountId
    ? saveApiBase("UPDATE_STUDY_GROUP", { id: props.id, name: props.name, accountId: props.accountId })
    : saveApiBase("UPDATE_STUDY_GROUP", { id: props.id, name: props.name });
};

export const updateLegalCurriculumApi = (props: {
  id: number;
  legalCurriculumTemplateId: number;
  studyGroupIds: number[];
  dtls: {
    month: number;
    themeId: number;
    monthOrd: number;
    themeOrd: number;
  }[];
  accountId?: string;
}) => {
  return props.accountId
    ? saveApiBase("UPDATE_LEGAL_CURRICULUM", props)
    : saveApiBase("UPDATE_LEGAL_CURRICULUM", {
        id: props.id,
        legalCurriculumTemplateId: props.legalCurriculumTemplateId,
        studyGroupIds: props.studyGroupIds,
        dtls: props.dtls,
      });
};

export const updateCategoryGenreApi = (props: { genres: { id: number; name: string; ord: number }[] }) => {
  return saveApiBase("UPDATE_CATEGORY_GENRE", props);
};

export const updateAnnounceApi = (props: {
  id: number;
  announceType: announceType;
  htmlFlg: boolean | number;
  importantFlg: boolean | number;
  title: string;
  contents: string;
  target: announceTargetType;
  publicPageFlg: boolean | number;
  startDate: Date | string | null;
  bannerEndDate: Date | string | null;
  fileObjectKey: string;
  fileName: string | undefined;
  linkLabel: string | undefined;
}) => {
  return saveApiBase("UPDATE_ANNOUNCE", props);
};

export const updateUserAdditionalInfoApi = (props: {
  lastName: string;
  firstName: string;
  occupation: additionalInfoOccupationType;
  experienceYear: additionalInfoExperienceYearType;
  joinedCompanyDate?: Date | string | null;
  birthDate?: Date | string | null;
}) => {
  return saveApiBase("UPDATE_USER_INFO", props);
};

export const updateTutorialClosedApi = () => {
  return saveApiBase("UPDATE_TUTORIAL_CLOSED");
};

export const updateTutorialDisplayedApi = () => {
  return saveApiBase("UPDATE_TUTORIAL_DISPLAYED");
};

export const updateChangeLoginSuccessApi = (props: {
  loginInfo:
    | {
        loginType: "PASSWORD";
        password: string;
      }
    | { loginType: "EMAIL"; mailAddress: string }
    | { loginType: "SMS"; phoneNumber: string };
}): Promise<void> => {
  return saveApiBase("UPDATE_CHANGE_LOGIN_SUCCESS", { ...props.loginInfo });
};

export const updatePackageApi = (params: apiUpdatePackage) => {
  return saveApiBase("UPDATE_PACKAGE", params);
};

export const deleteBookmarkApi = (props: { accountOriginalFlg: boolean; contentsId: number }) => {
  return saveApiBase("DELETE_BOOKMARK", props);
};

export const deleteAccountApi = (props: { id: string }) => {
  return saveApiBase("DELETE_ACCOUNT", props);
};

export const deleteCorporationAccountApi = (props: { id: string }) => {
  return saveApiBase("DELETE_CORPORATION_ACCOUNT", props);
};

export const deleteUserApi = (props: { id: number }) => {
  return saveApiBase("DELETE_USER", props);
};

export const deleteCategoryApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("DELETE_CATEGORY", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const deleteThemeApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("DELETE_THEME", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const deleteContentsApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("DELETE_CONTENTS", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const deleteContentsWorkApi = (props: { accountOriginalFlg: boolean; accountId?: string; id: number }) => {
  return saveApiBase("DELETE_CONTENTS_WORK", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const deleteTestTemplateWorkApi = (props: { testTemplateWorkId: number }) => {
  return saveApiBase("DELETE_TEST_TEMPLATE_WORK", props);
};

export const deleteContentsMediaApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  id: number | string;
}) => {
  return saveApiBase("DELETE_CONTENTS_MEDIA", {
    id: props.id,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const deleteStudyGroupApi = (props: { id: number; accountId?: string }) => {
  return props.accountId
    ? saveApiBase("DELETE_STUDY_GROUP", { id: props.id, accountId: props.accountId })
    : saveApiBase("DELETE_STUDY_GROUP", { id: props.id });
};

export const deleteLegalCurriculumApi = (props: { id: number; accountId?: string }) => {
  return props.accountId
    ? saveApiBase("DELETE_LEGAL_CURRICULUM", { id: props.id, accountId: props.accountId })
    : saveApiBase("DELETE_LEGAL_CURRICULUM", { id: props.id });
};

export const deleteLegalCurriculumStudiedForceApi = (props: { id: number }) => {
  return saveApiBase("DELETE_LEGAL_CURRICULUM_STUDIED_FORCE", props);
};

export const deleteCareerExclusionApi = (props: {
  studyGroupId: number;
  careerType: careerType;
  level: number;
  accountId?: string;
}) => {
  return props.accountId
    ? saveApiBase("DELETE_CAREER_EXCLUSION", props)
    : saveApiBase("DELETE_CAREER_EXCLUSION", {
        studyGroupId: props.studyGroupId,
        careerType: props.careerType,
        level: props.level,
      });
};

export const deleteAnnounceApi = (props: { id: number }) => {
  return saveApiBase("DELETE_ANNOUNCE", props);
};

export const deleteCategoryGenreApi = (props: { id: number }) => {
  return saveApiBase("DELETE_CATEGORY_GENRE", props);
};

export const deleteInvite = (props: { inviteUlid: string }): Promise<void> => {
  return saveApiBase("DELETE_INVITE", props);
};

export const deletePersonnelChangeApi = (props: { personnelChangeLogId: number }) => {
  return saveApiBase("DELETE_PERSONNEL_CHANGE", props);
};

export const startPackageApi = (props: { packageId: number }) => {
  return saveApiBase("START_PACKAGE", props);
};

export const updateAccountNoticeApi = (props: apiUpdateAccountNotice) => {
  return saveApiBase("UPDATE_ACCOUNT_NOTICE", props);
};

export const registerGroupStudyApi = (props: {
  groupStudyHistoryId: number;
  accountOriginalFlg: boolean;
  contentsId: number;
  userIds: number[];
}) => {
  return saveApiBase("REGISTER_GROUP_STUDY", props);
};

export const completeGroupStudyApi = (props: { groupStudyHistoryId: number; accountOriginalFlg: boolean }) => {
  return saveApiBase("COMPLETE_GROUP_STUDY", props);
};

export const completeGroupStudyViewApi = (props: {
  groupStudyHistoryId: number;
  accountOriginalFlg: boolean;
  packageFlg: boolean;
  packageId: number;
}) => {
  return saveApiBase("COMPLETE_GROUP_STUDY_VIEW", props);
};

export const updateGroupStudyUserApi = (props: {
  groupStudyHistoryId: number;
  accountOriginalFlg: boolean;
  userIds: number[];
}) => {
  return saveApiBase("UPDATE_GROUP_STUDY_USER", props);
};

export const registerGroupStudyTestResultApi = (props: apiRegisterTest & { groupStudyHistoryId: number }) => {
  return saveApiBase("REGISTER_GROUP_STUDY_TEST_RESULT", props);
};

export const sendReportApi = (props: apiSendReport) => {
  return saveApiBase("SEND_REPORT", props);
};

export const updateBulkUserStudyGroupApi = (props: {
  studyGroupId: number;
  accountId?: string;
  bulkTargetUserIds: number[];
}) => {
  return saveApiBase("UPDATE_BULK_USER_STUDY_GROUP", props);
};
